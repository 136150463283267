import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './ProductCard.css';
import { fetchProductData } from '../../../Services/ProductServices';
import KOKO from '../../../assests/ProductCard/Koko.png';
import Mintpay from '../../../assests/ProductCard/Mintpay.png';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProduct, setModalOpen, fetchProductDetails } from '../../../slices/ProductSlice';
import { Image_URL } from '../../../config/config';
import ProductViewModal from '../modalComponent/productModal/index';
import { useNavigate, useLocation } from 'react-router-dom';
import { toggleWishlistProduct } from '../../../slices/wishlistSlice';

const ProductCard = ({ product: propProduct }) => {
  const [productData, setProductData] = useState([]);
  const [selectedImages, setSelectedImages] = useState({});
  const [mainImage, setMainImage] = useState({});
  const location = useLocation();
  const wishlistProductIds = useSelector((state) => state.wishlist.wishlistProductIds);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await fetchProductData();
        if (Array.isArray(products)) {
          setProductData(products);
        } else {
          console.error('Fetched data is not an array:', products);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (!propProduct) {
      fetchData();
    }
  }, [propProduct]);

  const handleAddToCart = useCallback((product) => {
    dispatch(setSelectedProduct(product));
    dispatch(setModalOpen(true));
  }, [dispatch]);

  const getUniqueColors = useCallback((colors) => {
    const uniqueColors = [];
    const seenColors = new Set();
    colors.forEach((color) => {
      if (!seenColors.has(color.Color)) {
        seenColors.add(color.Color);
        uniqueColors.push(color);
      }
    });
    return uniqueColors;
  }, []);

  const handleColorClick = useCallback((productId, color) => {
    const sources = color.Sources.split(',').map((source) => source.trim());
    setSelectedImages((prevState) => ({
      ...prevState,
      [productId]: sources,
    }));
    setMainImage((prevState) => ({
      ...prevState,
      [productId]: sources[0],
    }));
  }, []);

  const handleSmallImageClick = useCallback((productId, source) => {
    setMainImage((prevState) => ({
      ...prevState,
      [productId]: source,
    }));
  }, []);

  const handleMainImageClick = (Slug) => {
    navigate(`/product/${Slug}`);
  };

  const handleOpenModal = useCallback(() => {
    dispatch(fetchProductDetails(propProduct.Slug));
    dispatch(setModalOpen(true));
  }, [dispatch, propProduct]);

  const toggleWishlist = useCallback(() => {
    dispatch(toggleWishlistProduct(propProduct.ProductId));
    if (wishlistProductIds.includes(propProduct.ProductId) && location.pathname === '/wishlist') {
      window.location.reload();
    }
  }, [dispatch, propProduct.ProductId, wishlistProductIds, location.pathname]);

  if (!propProduct) return null;

  const renderProduct = (product) => {
    const { ProductId, Price, Title, Colors, Slug } = product;
    const installmentPrice = Math.floor(Price / 3);
    const firstColorSources = (Colors && Colors[0] && Colors[0].Sources ? Colors[0].Sources.split(',') : []);
    const uniqueColors = getUniqueColors(Colors || []);
    const currentMainImage = mainImage[ProductId] || (firstColorSources.length > 0 && firstColorSources[0]);
    const currentSelectedImages = selectedImages[ProductId] || firstColorSources;
    const isWishlisted = wishlistProductIds.includes(ProductId);

    return (
      <div className="product-card-container my-3" key={ProductId}>
        <div className="product-card">
          <div className="product-card-body">
            {/* Main Image */}
            <div className="product-main-image-container">
              {currentMainImage && (
                <img
                  src={`${Image_URL}products/thumbnails/${currentMainImage}`}
                  className="product-main-image"
                  alt="Product"
                  onClick={() => handleMainImageClick(Slug)}
                  onError={(e) => {
                    e.target.src = 'https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg';
                  }}
                />
              )}
              <div className="heart-icon-container" onClick={toggleWishlist}>
                {isWishlisted ? (
                  <i className="fa-solid fa-heart" style={{ color: 'red' }}></i>
                ) : (
                  <i className="fa-regular fa-heart"></i>
                )}
              </div>
            </div>

            {/* Small Images - Positioned outside the main image */}
            <div className={`product-small-images-container ${currentSelectedImages.length === 2 ? 'two-images' : ''}`}>
              {currentSelectedImages.slice(0, 3).map((source, index) => (
                <div
                  key={index}
                  className="product-small-image-wrapper"
                  onClick={() => handleSmallImageClick(ProductId, source)}
                >
                  <img
                    src={`${Image_URL}products/thumbnails/${source.trim()}`}
                    className="product-small-image"
                    alt={`Product view ${index + 1}`}
                    onError={(e) => {
                      e.target.src = 'https://jbadmin.alephaz.com/assets/img/placeholder-image.jpg';
                    }}
                  />
                </div>
              ))}
            </div>

            <div className="product-color-dots-container">
              {uniqueColors.slice(0, 4).map((color, index) => (
                <div
                  key={index}
                  className="product-color-dot"
                  style={{ backgroundColor: color.Color }}
                  title={color.Color}
                  onClick={() => handleColorClick(ProductId, color)}
                ></div>
              ))}
            </div>
            <div className="product-text-and-button-container">
              <div className="product-text-container">
                <p className="product-name" onClick={() => handleMainImageClick(Slug)}>{Title}</p>
                <div>
                  <h5 className="product-price">
                    <span className="product-highlighted-price">Rs {Price}</span>
                  </h5>
                  <p className="product-installments">or 3 installments of Rs {installmentPrice} with</p>
                  <div className="payment-logos-container">
                    <img src={Mintpay} alt="MintPay" className="product-payment-logo me-2" />
                    <p className="product-installments">or</p>
                    <img src={KOKO} alt="Koko" className="product-payment-logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {propProduct ? renderProduct(propProduct) : productData.map(renderProduct)}
      <ProductViewModal />
    </>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    ProductId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    Price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    Title: PropTypes.string.isRequired,
    Colors: PropTypes.arrayOf(
      PropTypes.shape({
        Color: PropTypes.string.isRequired,
        Sources: PropTypes.string.isRequired,
      })
    ).isRequired,
    Slug: PropTypes.string.isRequired,
  }),
};

export default ProductCard;
