import React, { useEffect, useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Tabs.css";
import { fetchReviewsByProductId } from '../../../Services/reviewServices';

const DynamicTabs = ({ product, productId }) => {
  // Parse KeyFeatures JSON
  const keyFeatures = JSON.parse(product.KeyFeatures || "[]");

  // Log parsed KeyFeatures
  console.log("Parsed KeyFeatures:", keyFeatures);

  // Generate HTML for KeyFeatures
  const keyFeaturesHTML = keyFeatures.length > 0 ? keyFeatures.map((feature, index) => (
    <div key={index}>
      <h4>{feature.feature}</h4>
      <ul>
        {feature.points.map((point, i) => <li key={i}>{point}</li>)}
      </ul>
    </div>
  )) : <p>No key features available.</p>;

  // Split KeyPoints string into an array of points
  const keyPointsArray = product.KeyPoints
    ? product.KeyPoints.split(/[,\.]/).map(point => point.trim()).filter(point => point)
    : [];

  // Log parsed KeyPointsArray
  console.log("Parsed KeyPointsArray:", keyPointsArray);

  // State to hold reviews data
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Fetching reviews for product ID:", productId); // Log product ID

    const fetchReviews = async () => {
      try {
        const reviewsData = await fetchReviewsByProductId(productId);
        console.log("Fetched reviews data:", reviewsData); // Log fetched reviews data
        setReviews(reviewsData);
      } catch (error) {
        console.error('Error fetching reviews:', error); // Log error
        setError('Error fetching reviews');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId]);

  // Log reviews state whenever it changes
  useEffect(() => {
    console.log("Reviews state updated:", reviews);
  }, [reviews]);

  // Log loading and error state changes
  useEffect(() => {
    console.log("Loading state:", loading);
    console.log("Error state:", error);
  }, [loading, error]);

  return (
    <Tabs>
      <TabList>
        <Tab>Description</Tab>
        <Tab>Specifications</Tab>
        <Tab>Ratings and Reviews</Tab>
      </TabList>

      <TabPanel>
        {keyPointsArray.length > 0 ? (
          <ul>
            {keyPointsArray.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        ) : (
          <p>No key points available.</p>
        )}
      </TabPanel>
      
      <TabPanel>
        <div>{keyFeaturesHTML}</div>
      </TabPanel>
      
      <TabPanel>
        {loading ? (
          <p>Loading reviews...</p>
        ) : error ? (
          <p>{error}</p>
        ) : reviews.length > 0 ? (
          <ul>
            {reviews.map((review) => (
              <li key={review.ReviewId}>
                <strong>Review by User {review.UserId}:</strong> {review.ReviewText}
                <br />
                <em>Rating: {review.Rating}</em>
                <br />
                <small>Reviewed on: {new Date(review.DateCreated).toLocaleDateString()}</small>
              </li>
            ))}
          </ul>
        ) : (
          <p>No reviews available.</p>
        )}
      </TabPanel>
    </Tabs>
  );
};

export default DynamicTabs;
